import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Item from "./Item";

const SortableItem = ({
  images,
  item,
  index,
  photoOrder,
  onDeleteImage,
  ...props
}) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: item.id,
  });

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <Item
      images={images}
      item={item}
      index={index}
      ref={setNodeRef}
      style={styles}
      photoOrder={photoOrder}
      isOpacityEnabled={isDragging}
      onDeleteImage={onDeleteImage}
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};

export default SortableItem;
