function Header() {
  return (
    <div
      className="
        flex
        h-16
        w-full
        items-center
      bg-[#33a852]
        py-2
        font-bold
      text-black
      "
    >
      <span>
        <img
          src="./images/okushin.png"
          alt="okushin"
          className="
            ml-2
            mr-2
            h-10
            w-10
          "
        />
      </span>
      <h1 className="text-white">
        {" X(Twitter)ポスト文字数カウント"}
        <br className="block sm:hidden" />
        {"& プレビューツール by SNSの学校"}
      </h1>
    </div>
  );
}

export default Header;
