import { forwardRef } from "react";

const Item = forwardRef(
  (
    {
      item,
      index,
      isOpacityEnabled,
      isDragging,
      onDeleteImage,
      style,
      photoOrder,
      ...props
    },
    ref,
  ) => {
    const styles = {
      opacity: isOpacityEnabled ? "0.4" : "1",
      cursor: isDragging ? "grabbing" : "grab",
      lineHeight: "0.5",
      transform: isDragging ? "scale(1.05)" : "scale(1)",
      ...style,
    };

    return (
      <div className="relative">
        <div ref={ref} style={styles} {...props}>
          <img
            className="thumbnail h-auto max-h-[200px] w-full rounded object-cover"
            src={item.url}
          />
        </div>
        <div
          className="delete-button absolute right-1 top-1 cursor-pointer rounded-full bg-black bg-opacity-50 px-2 py-1 text-xs text-white"
          onClick={() => onDeleteImage(index)}
        >
          ×
        </div>
      </div>
    );
  },
);

export default Item;
