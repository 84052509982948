const timeLinePreviewText = (text, index = -1) => {
  // 区切りインデックスがある場合はそこまでの文字を切り取って表示
  const textArray = [...text];
  const sourceText = index === -1 ? text : textArray.slice(0, index).join("");
  let outputText = sourceText
    .trim()
    .replaceAll(/\n{2,}/g, "\n")
    .replaceAll(/\n/g, "<br/>")
    .replaceAll(
      /(https?:\/\/[^\s]+)/g,
      '<a href="$&" class="text-blue-700">$&</a>',
    )
    .replaceAll(/(#[^\s]+ )/g, '<span class="text-blue-700">$&</span>');
  if (index !== -1) {
    outputText =
      outputText + `...<span class="text-blue-500">さらに表示</span>`;
  }

  return outputText;
};

export { timeLinePreviewText };
