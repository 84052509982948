import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
  closestCenter,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import Item from "./Item";

const Thumbnails = ({
  photoOrder,
  images,
  onDeleteImage,
  activeItem,
  onDragStart,
  onDragEnd,
  onDragCancel,
}) => {
  // for input methods detection
  const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

  return (
    <>
      <p className={`${images.length > 0 ? "block" : "hidden"}`}>
        ドラッグ&ドロップで画像の入れ替えができます
      </p>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragCancel={onDragCancel}
      >
        <SortableContext items={images} strategy={rectSortingStrategy}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(4, 1fr)`,
              gridGap: 16,
              maxWidth: "800px",
              margin: "16px auto 48px",
            }}
          >
            {images.map((item, index) => (
              <SortableItem
                items={images}
                key={item.id}
                index={photoOrder[index]}
                item={item}
                photoOrder={photoOrder}
                onDeleteImage={onDeleteImage}
              />
            ))}
          </div>
        </SortableContext>
        <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
          {activeItem ? <Item item={activeItem} isDragging /> : null}
        </DragOverlay>
      </DndContext>
    </>
  );
};

export default Thumbnails;
