const count = (str) => {
  let len = 0;
  for (let i = 0; i < str.length; i++) {
    ishalf(str[i]) ? (len += 1) : (len += 2); //半角文字、改行を1文字、それ以外を2文字
  }
  return len;
};

const ishalf = (char) => {
  return char.match(/[ -~\n]/);
};

//　さらに表示が表示される文字位置をindexとして返す関数
const countShowMoreIndex = (text) => {
  // URLを23文字換算にする
  const replacedText = [
    ...text.replaceAll(/(https?:\/\/[^\s]+)/g, "https://t.co/xxxxxxxxxx"),
  ];

  let indices = [];
  let wordCount = 0;

  for (var i = 0; i < replacedText.length; i++) {
    if (count(replacedText) <= 280) return -1;
    if (wordCount > 280) {
      if (indices.length === 0) indices.push(i);
      break;
    }
    const charAt = replacedText[i];
    // 280文字に到達したら区切られた文章の最終インデックスを返して終了

    // 以下、280文字未達時の処理
    if (ishalf(charAt)) {
      wordCount += 1;
    } else {
      wordCount += 2;
    }

    // 区切り文字が出てきたら、区切られた文字番号indexをindicesに追加
    const regex = /\r?\n| /g;
    if (regex.test(replacedText[i])) {
      indices.push(i);
    }
  }
  console.log(indices);
  return indices.at(-1);
};

export { count, countShowMoreIndex };
