import twitterText from "twitter-text";
import BannerMobile from "./BannerMobile";
import { ButtonWithInputFile } from "./ButtonWithInputFile";
import Thumbnails from "./Thumbnails";

const Input = (props) => {
  const textLength = [...props.text].length;
  const parsedTweet = twitterText.parseTweet(props.text);

  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(props.text);
    alert("テキストをコピーしました");
  };

  //新しいタブで開く
  const handleClickPostToX = () => {
    window.open(
      `https://x.com/compose/post?text=${encodeURIComponent(props.text)}`,
      "_blank",
    );
  };

  const kanjiRate = (
    Math.round((props.kanji / textLength) * 10000) / 100
  ).toFixed(2);

  return (
    <div className="h-auto w-full flex-row px-6 py-4 lg:w-2/3 lg:max-w-[1300px]">
      <h2 className="mb-2 text-[20px] font-bold text-gray-900">
        テキストを入力してください
      </h2>
      <textarea
        id="post-text"
        text={props.text}
        value={props.value}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.onChange(e);
          // 改行に合わせて高さを変える
          e.target.style.height = e.target.scrollHeight + "px";
        }}
        className="
          bg-white-50
          mb-2
          h-auto
          min-h-48
          w-full
          text-wrap
          rounded-lg
          border
          border-gray-300
          p-2
          text-base
          text-gray-900
          focus:border-blue-200
          focus:bg-yellow-100
          focus:ring-blue-100
          sm:p-2.5
        "
        placeholder="いまどうしてる？"
      />

      <div className="flex items-center sm:justify-end">
        <ButtonWithInputFile
          inputProps={{
            type: "file",
            accept: "image/png",
            onChange: props.onFileSelect,
          }}
        >
          <span
            className="
              icon-[fa6-regular--image]
              h-5
              w-5
              text-white
              mr-1
            "
          /><span className="text-white">画像</span>
        </ButtonWithInputFile>
        <button
          type="button"
          className="
            mb-2
            me-2
            w-48
            h-12
            rounded-lg
            bg-gray-300
            p-1
            py-2.5
            text-xs
            font-medium
            text-black
            active:bg-twitter
            sm:w-48
            sm:px-5
            sm:text-sm
          dark:bg-gray-300
          dark:hover:bg-gray-200
          dark:focus:ring-gray-800
          "
          onClick={props.deleteText}
        >
          <span
            className="
            icon-[ph--eraser-light]
            right-0
            mr-1 
            h-5 
            w-5
          "
          ></span>
          クリア
        </button>
        <button
          type="button"
          className="
            mb-2
            me-2
            w-48
            h-12
            rounded-md
            bg-green-700
            p-1
            py-2.5
            text-xs
            font-medium
            text-white
            active:bg-green-800
            sm:w-48
            sm:rounded-lg
            sm:px-5
            sm:text-sm
          dark:bg-green-600
          dark:hover:bg-green-700
          dark:focus:ring-green-800
          "
          onClick={() => copyToClipboard()}
        >
          <span
            className="
            bottom- 
            icon-[ph--copy] 
            right-0 
            mr-1 
            h-5 
            w-5
          "
          ></span>
          コピー
        </button>
        <button
          type="button"
          className="
            mb-2
            h-12
            w-48
            rounded-md
            bg-black
            p-1
            text-xs
            font-medium
            text-white  
            active:bg-black
            sm:w-48
            sm:rounded-lg
            sm:px-5
            sm:py-2.5
            sm:text-sm
          dark:bg-black
          dark:hover:bg-gray-700
          dark:focus:ring-gray-800
          "
          onClick={() => handleClickPostToX()}
        >
          <span className="text-xl text-white">𝕏</span> ポスト
        </button>
      </div>

      <Thumbnails
        activeItem={props.activeItem}
        setActiveItem={props.setActiveItem}
        photoOrder={props.photoOrder}
        images={props.images}
        onDeleteImage={() => props.onDeleteImage()}
        onDragStart={props.onDragStart}
        onDragEnd={props.onDragEnd}
        onDragCancel={props.onDragCancel}
      />

      <h2 className="mb-2 mt-4 text-[20px] font-bold text-gray-900">
        文字カウント・構成比率
      </h2>
      <div className="rounded-md bg-gray-100 p-2 text-[14px]">
        <div className="flex items-center justify-between px-1">
          <p className="w-48 text-[14px] ">テキスト文字数：</p>
          <div>
            <span className="mr-1 text-[25px] font-bold">{textLength}</span>
            <span className="m-1 inline-block w-8 text-right">文字</span>
          </div>
        </div>

        <div className="flex items-center justify-between px-1">
          <p className="w-48">X（Twitter）上の文字数：</p>
          <div>
            <span
              className={`
                mr-1
                text-right
                text-[25px]
                font-bold
                ${parsedTweet.weightedLength / 2 > 140 ? "text-red-600" : ""}
              `}
            >
              {parsedTweet.weightedLength / 2}
            </span>
            <span className="m-1 inline-block w-8 text-right">文字</span>
          </div>
        </div>
        <div
          className={`flex items-center justify-between px-1 ${kanjiRate >= 30 ? "text-red-600" : "text-black"}`}
        >
          <p>漢字比率：</p>
          <div>
            <div className="">
              <span className="mr-1 text-[25px] font-bold">
                {props.text ? kanjiRate : "-"}
              </span>
              <span className="m-1 inline-block w-8 text-right">%</span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between px-1">
          <p>ひらがな比率：</p>
          <div>
            <span className="mr-1 text-[25px] font-bold">
              {props.text
                ? (
                    Math.round((props.hiragana / textLength) * 10000) / 100
                  ).toFixed(2)
                : "-"}
            </span>
            <span className="m-1 inline-block w-8 text-right">%</span>
          </div>
        </div>
        <div className="flex items-center justify-between px-1">
          <p>全角カタカナ比率：</p>
          <div>
            <span className="mr-1 text-[25px] font-bold">
              {props.text
                ? (
                    Math.round((props.fullKatakana / textLength) * 10000) / 100
                  ).toFixed(2)
                : "-"}
            </span>
            <span className="m-1 inline-block w-8 text-right">%</span>
          </div>
        </div>
        <div className="flex items-center justify-between px-1">
          <p>英数字（半角）比率：</p>
          <div>
            <span className="mr-1 text-[25px] font-bold">
              {props.text
                ? (
                    Math.round((props.halfAlphanumeric / textLength) * 10000) /
                    100
                  ).toFixed(2)
                : "-"}
            </span>
            <span className="m-1 inline-block w-8 text-right">%</span>
          </div>
        </div>
        <div className="flex items-center justify-between px-1">
          <p>英数字（全角）比率：</p>
          <div>
            <span className="mr-1 text-[25px] font-bold">
              {props.text
                ? (
                    Math.round((props.fullAlphanumeric / textLength) * 10000) /
                    100
                  ).toFixed(2)
                : "-"}
            </span>
            <span className="m-1 inline-block w-8 text-right">%</span>
          </div>
        </div>
        <div className="flex items-center justify-between px-1">
          <p className="">その他（句読点など）比率：</p>
          <div>
            <span className="mr-1 text-[25px] font-bold">
              {props.text
                ? (
                    Math.round((props.other / textLength) * 10000) / 100
                  ).toFixed(2)
                : "-"}
            </span>
            <span className="m-1 inline-block w-8 text-right">%</span>
          </div>
        </div>
        <div className="flex items-center justify-between p-1">
          <p className="w-48">リンク数：</p>
          <div>
            <span className="mr-1 text-[25px] font-bold">
              {twitterText.extractUrls(props.text).length}
            </span>
            <span className="m-1 inline-block w-8 text-right">個</span>
          </div>
        </div>
      </div>
      <BannerMobile isDisplayed={props.isMobile} />
      <h2 className="mr-1 mt-8 text-[19px] font-bold text-gray-900 sm:text-[20px]">
        ※X上での文字数カウントのルール
      </h2>
      <ul className="w-full rounded-md border bg-green-100 px-4 py-2">
        <li>
          半角文字：<span className="font-bold">0.5</span>文字
        </li>
        <li>
          全角文字：<span className="font-bold">1</span>文字
        </li>
        <li>
          改行：<span className="font-bold">0.5</span>文字
        </li>
        <li>
          URL：<span className="font-bold">11.5</span>文字
        </li>
      </ul>
      <p className="mt-2">でカウントされます</p>
    </div>
  );
};

export default Input;
