const Banner = ({ isDisplayed }) => {
  const bannerNum = calcBannerNumber();
  const bannerUrl = `https://${process.env.REACT_APP_S3_DOMAIN}/banners/banner-${bannerNum}.png`;
  const adUrl = `https://${process.env.REACT_APP_S3_DOMAIN}/ads/banner-${bannerNum}/index.html`;

  return (
    <a
      className={`mx-2 my-8 justify-center ${isDisplayed ? "flex" : "hidden"}`}
      href={adUrl}
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={bannerUrl}
        alt={`banner-${bannerNum}`}
      />
    </a>
  );
};

const calcBannerNumber = () => {
  return Math.floor(Math.random() * 3) + 1;
};

export default Banner;
