import { useRef } from "react";

// /* inputやbuttonタグに渡せる属性の一覧 */
// type NativeInputProps = React.InputHTMLAttributes<HTMLInputElement>;
// type NativeButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * - input自体は常に非表示にするためstyleは指定不可とする
 * - refはボタンと紐付けるために使用するため指定不可する
 * - typeは`file`のみを受け付ける
 */
// type InputProps = Omit<NativeInputProps, 'type' | 'style' | 'ref'> & { type: 'file' };

/**
 * - onClickはinputを発火するために使用するため指定不可とする
 */
// type ButtonProps = Omit<NativeButtonProps, 'onClick'>;

// type ButtonWithInputFileProps = ButtonProps & { inputProps: InputProps };

export const ButtonWithInputFile = ({
  children,
  inputProps,
  ...buttonProps
}) => {
  const fileInputRef = useRef(null);

  return (
    <div
      className="
      mb-2
      me-2
      w-48
    "
    >
      <button
        type="button"
        className="

          h-12
          w-full
          rounded-lg
          bg-twitter
          p-1
          py-2.5
          text-xs
          font-medium
          text-black
          hover:bg-sky-400
          active:bg-twitter
          sm:w-48
          sm:px-5
          sm:text-sm
        dark:bg-twitter
        dark:hover:bg-sky-300
        dark:focus:ring-gray-800
        "
        {...buttonProps}
        onClick={() => fileInputRef.current?.click()}
      >
        {children}
      </button>
      <input
        type="file"
        accept="image/*"
        multiple
        {...inputProps}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
    </div>
  );
};
