import Header from "./components/Header.jsx";
import Input from "./components/Input.jsx";
import Preview from "./components/Preview.jsx";
import {
  isHiragana,
  isKanji,
  isFullKatakana,
  isHalfAlphanumeric,
  isFullAlphanumeric,
} from "./util/char.js";
import { countShowMoreIndex } from "./util/word";
import { timeLinePreviewText } from "./util/text";

import { useState, useEffect } from "react";
import {
  arrayMove,
} from "@dnd-kit/sortable";

function App() {
  const [text, setText] = useState("");
  const [previewText, setPreviewText] = useState("");
  const [hiragana, setHiragana] = useState(0);
  const [kanji, setKanji] = useState(0);
  const [fullKatakana, setFullKatakana] = useState(0);
  const [halfAlphanumeric, setHalfAlphanumeric] = useState(0);
  const [fullAlphanumeric, setFullAlphanumeric] = useState(0);
  const [other, setOther] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [images, setImages] = useState([]);
  const [photoOrder, setPhotoOrder] = useState([]);

  // for drag overlay
  const [activeItem, setActiveItem] = useState();

  // triggered when dragging starts
  const handleDragStart = (event) => {
    const { active } = event;
    setActiveItem(images.find((item) => item.id === active.id));
  };

  // triggered when dragging ends
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    const activeItem = images.find((item) => item.id === active.id);
    const overItem = images.find((item) => item.id === over.id);

    if (!activeItem || !overItem) {
      return;
    }

    const activeIndex = images.findIndex((item) => item.id === active.id);
    const overIndex = images.findIndex((item) => item.id === over.id);

    if (activeIndex !== overIndex) {
      setImages((prev) => arrayMove(prev, activeIndex, overIndex));
    }
    setActiveItem(undefined);
  };

  const handleDragCancel = () => {
    setActiveItem(undefined);
  };

  useEffect(() => {
    setIsMobile(window.innerWidth < 500);
  }, [isMobile]);

  const handleChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setText(e.target.value);
    countChar(e.target.value);
    const outputText = generatePreviewText(e.target.value);
    setPreviewText(outputText);
  };

  const generatePreviewText = (text) => {
    const index = countShowMoreIndex(text);
    return timeLinePreviewText(text, index);
  };

  const handleDeleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);

    const newPhotoOrder = photoOrder.filter(
      (photoIndex) => photoIndex !== index,
    );
    setPhotoOrder(
      newPhotoOrder.map((photoIndex) =>
        photoIndex > index ? photoIndex - 1 : photoIndex,
      ),
    );
  };

  // const handleSwapImages = (index1, index2) => {
  //   if (index1 >= 0 && index1 < images.length && index2 >= 0 && index2 < images.length) {
  //     const newPhotoOrder = [...photoOrder];
  //     const temp = newPhotoOrder[index1];
  //     newPhotoOrder[index1] = newPhotoOrder[index2];
  //     newPhotoOrder[index2] = temp;
  //     setPhotoOrder(newPhotoOrder);
  //   }
  // };

  const countChar = (text) => {
    const textArray = [...text];
    setHiragana(0);
    setKanji(0);
    setFullKatakana(0);
    setHalfAlphanumeric(0);
    setFullAlphanumeric(0);
    setOther(0);
    for (var i = 0; i < textArray.length; i++) {
      const character = textArray[i];
      const code = character.charCodeAt(0);
      if (isHiragana(code)) {
        setHiragana((hiragana) => hiragana + 1);
      } else if (isKanji(code)) {
        setKanji((kanji) => kanji + 1);
      } else if (isFullKatakana(code)) {
        setFullKatakana((fullKatakana) => fullKatakana + 1);
      } else if (isHalfAlphanumeric(code)) {
        setHalfAlphanumeric((halfAlphanumeric) => halfAlphanumeric + 1);
      } else if (isFullAlphanumeric(code)) {
        setFullAlphanumeric((fullAlphanumeric) => fullAlphanumeric + 1);
      } else {
        setOther((other) => other + 1);
      }
    }
  };

  const deleteText = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setText("");
    const outputText = generatePreviewText(e.target.value);
    setPreviewText(outputText);
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;
    if (files.length < 1 || files.length > 4) {
      return;
    }

    if (images.length + files.length > 4) {
      alert("画像は最大4枚までです");
      return;
    }

    const newImages = [];
    for (let i = 0; i < files.length; i++) {
      newImages.push({
        id: Date.now() + i,
        url: URL.createObjectURL(files[i]),
      });
    }
    setImages([...images, ...newImages]);
    setPhotoOrder(
      Array.from({ length: images.length + files.length }, (_, i) => i),
    );
  };

  return (
    <div className="w-max-[1500px]">
      <Header />
      <div className="h-auto flex-row lg:flex lg:min-h-[93vh]">
        <Input
          isMobile={isMobile}
          value={text}
          text={text}
          previewText={previewText}
          hiragana={hiragana}
          kanji={kanji}
          fullKatakana={fullKatakana}
          halfAlphanumeric={halfAlphanumeric}
          fullAlphanumeric={fullAlphanumeric}
          other={other}
          onChange={(e) => handleChange(e)}
          deleteText={(e) => deleteText(e)}
          photoOrder={photoOrder}
          images={images}
          onFileSelect={handleFileSelect}
          onDeleteImage={handleDeleteImage}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        />
        <Preview
          text={text}
          isMobile={isMobile}
          previewText={previewText}
          images={images}
          photoOrder={photoOrder}
        />
      </div>
    </div>
  );
}

export default App;
