

const isHiragana = (code) => {
	return 0x3040 <= code && code <= 0x309F; // 平仮名 (ぁ-ゟ)
}

const isKanji = (code) => {
	return (0x4E00 <= code && code <= 0x9FCF) // CJK統合漢字 (一-龥)
		|| (0x3400 <= code && code <= 0x4DBF) // CJK統合漢字拡張A (㐂-䶮)
		|| (0xF900 <= code && code <= 0xFAFF) // CJK互換漢字 (豈-頻)
		|| code === 0x3005 || code === 0x3007 || code === 0x303B; // CJKの記号及び句読点 (々〇〻)
}

const isFullKatakana = (code) => {
	return (0x30A0 <= code && code <= 0x30FF) // 片仮名 (゠-ヿ)
		|| (0x31F0 <= code && code <= 0x31FF); // 片仮名拡張 (ㇰ-ㇿ)
}

const isHalfAlphanumeric = (code) => {
    return (0x0030 <= code && code <= 0x0039) // ASCII数字 (0-9)
        || (0x0041 <= code && code <= 0x005A) // 大文字ラテン・アルファベット (A-Z)
        || (0x0061 <= code && code <= 0x007A) // 小文字ラテン・アルファベット (a-z)
}

const isFullAlphanumeric = (code) => {
    return (0xFF10 <= code && code <= 0xFF19) // 全角ASCII (０-９)
        || (0xFF21 <= code && code <= 0xFF3A) // 全角ASCII(Ａ-Ｚ)
		|| (0xFF41 <= code && code <= 0xFF5A); // 全角ASCII (ａ-ｚ)
}

export { 
	isHiragana, 
	isKanji, 
	isFullKatakana, 
	isHalfAlphanumeric, 
	isFullAlphanumeric
};