import React from "react";
import PreviewFooter from "./PreviewFooter";
import parse from "html-react-parser";
import BannerDesktop from "./BannerDesktop";
import ImageContainer from "./ImageContainer";

const Preview = (props) => {
  return (
    <div
      className="
        h-auto
        w-full
        flex-row
      bg-gray-100
        py-4
        sm:px-4
        lg:w-[720px]
      "
    >
      <h2
        className="
          mb-6
          ml-4
          text-xl
          font-bold
          text-gray-900
        sm:ml-1
        "
      >
        プレビュー表示
      </h2>
      <h3
        className="
          mb-2
          ml-4
          sm:ml-1"
      >
        タイムライン表示
      </h3>
      <div
        id="preview-detail"
        className="
          border-gray
          resize-auto
          mb-6
          mt-1
          flex
          h-auto
          w-full
          bg-white
          px-1
          py-2.5
          text-sm
          text-gray-900
          sm:p-4
        "
      >
        <img
          src="images/okushin_x.jpg"
          alt="プロフィール画像"
          className="
            me-[4px]
            h-[35px]
            w-[35px]
            rounded-full
            border-2
            sm:h-[40px]
            sm:w-[40px]
          "
        />
        <div
          className="
            w-full
            max-w-[365px]
            sm:w-[365px]
            lg:w-full
            lg:max-w-[550px]
          "
        >
          <div
            className="
              flex
              h-6
              w-full
              flex-wrap
              sm:w-[550px]
            "
          >
            <span className="h-full font-bold">オクシン</span>
            <svg viewBox="0 0 24 24" className="h-5 w-5 fill-blue-500">
              <path d="M20.396 11c-.018-.646-.215-1.275-.57-1.816-.354-.54-.852-.972-1.438-1.246.223-.607.27-1.264.14-1.897-.131-.634-.437-1.218-.882-1.687-.47-.445-1.053-.75-1.687-.882-.633-.13-1.29-.083-1.897.14-.273-.587-.704-1.086-1.245-1.44S11.647 1.62 11 1.604c-.646.017-1.273.213-1.813.568s-.969.854-1.24 1.44c-.608-.223-1.267-.272-1.902-.14-.635.13-1.22.436-1.69.882-.445.47-.749 1.055-.878 1.688-.13.633-.08 1.29.144 1.896-.587.274-1.087.705-1.443 1.245-.356.54-.555 1.17-.574 1.817.02.647.218 1.276.574 1.817.356.54.856.972 1.443 1.245-.224.606-.274 1.263-.144 1.896.13.634.433 1.218.877 1.688.47.443 1.054.747 1.687.878.633.132 1.29.084 1.897-.136.274.586.705 1.084 1.246 1.439.54.354 1.17.551 1.816.569.647-.016 1.276-.213 1.817-.567s.972-.854 1.245-1.44c.604.239 1.266.296 1.903.164.636-.132 1.22-.447 1.68-.907.46-.46.776-1.044.908-1.681s.075-1.299-.165-1.903c.586-.274 1.084-.705 1.439-1.246.354-.54.551-1.17.569-1.816zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z"></path>
            </svg>
            <span className="h-full text-slate-400">@OKU_MAYA</span>
          </div>
          <span
            className="
              mb-4
              w-[550px]
              break-words
              text-[13px]
              sm:text-sm
              sm:leading-tight
              lg:text-base
              
            "
          >
            {parse(props.previewText)}
          </span>

          <div className="mt-3">
            <ImageContainer
              images={props.images}
              photoOrder={props.photoOrder}
              numberOfPhotos={props.images.length}
              containerId="image-grid-1"
            />
          </div>
          <PreviewFooter />
        </div>
      </div>

      {/* ここから詳細表示 */}
      <h3 className="mb-2 ml-1">詳細表示</h3>
      <div
        id="preview-detail"
        className="
          border-gray
          resize-auto
          mb-6
          mt-1
          h-auto
          w-full
          flex-row
          bg-white
          p-4
          text-sm
          text-gray-900
          sm:p-4
        "
      >
        <div className="mb-6 flex">
          <img
            src="images/okushin_x.jpg"
            alt="プロフィール画像"
            className="
            me-2
            h-10
            w-[40px]
            rounded-full
            border-2
          "
          />
          <div
            className="
              mt-[2px]
              h-6
              w-full
              flex-row
              sm:w-[460px]
            "
          >
            <div className="flex h-4 items-center">
              <div className="h-auto font-bold">オクシン</div>
              <div>
                <svg viewBox="0 0 24 24" className="w-4 fill-blue-500">
                  <path d="M20.396 11c-.018-.646-.215-1.275-.57-1.816-.354-.54-.852-.972-1.438-1.246.223-.607.27-1.264.14-1.897-.131-.634-.437-1.218-.882-1.687-.47-.445-1.053-.75-1.687-.882-.633-.13-1.29-.083-1.897.14-.273-.587-.704-1.086-1.245-1.44S11.647 1.62 11 1.604c-.646.017-1.273.213-1.813.568s-.969.854-1.24 1.44c-.608-.223-1.267-.272-1.902-.14-.635.13-1.22.436-1.69.882-.445.47-.749 1.055-.878 1.688-.13.633-.08 1.29.144 1.896-.587.274-1.087.705-1.443 1.245-.356.54-.555 1.17-.574 1.817.02.647.218 1.276.574 1.817.356.54.856.972 1.443 1.245-.224.606-.274 1.263-.144 1.896.13.634.433 1.218.877 1.688.47.443 1.054.747 1.687.878.633.132 1.29.084 1.897-.136.274.586.705 1.084 1.246 1.439.54.354 1.17.551 1.816.569.647-.016 1.276-.213 1.817-.567s.972-.854 1.245-1.44c.604.239 1.266.296 1.903.164.636-.132 1.22-.447 1.68-.907.46-.46.776-1.044.908-1.681s.075-1.299-.165-1.903c.586-.274 1.084-.705 1.439-1.246.354-.54.551-1.17.569-1.816zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z"></path>
                </svg>
              </div>
            </div>
            <div className="text-slate-400">@OKU_MAYA</div>
          </div>
        </div>

        <div
          className="
          w-[88vw]
          max-w-[550px]
          sm:w-full
          sm:p-2
          "
        >
          <span
            className="
              w-full
              break-words
              text-[13px]
              leading-[6px]
              sm:text-base
            "
          >
            {parse(props.text.replaceAll(/\n/g, "<br/>"))}
          </span>
          <ImageContainer
            images={props.images}
            photoOrder={props.photoOrder}
            numberOfPhotos={props.images.length}
            containerId="image-grid-2"
          />
          <PreviewFooter />
        </div>
      </div>
      <BannerDesktop isDisplayed={!props.isMobile} />
    </div>
  );
};

export default Preview;
