import React, { useEffect, useCallback } from "react";

function ImageContainer({ images, photoOrder, numberOfPhotos, containerId }) {
  const updateLayout = useCallback(() => {
    const imageGrid = document.getElementById(containerId);

    imageGrid.innerHTML = "";

    if (numberOfPhotos === 1) {
      const imageWrapper = document.createElement("div");
      imageWrapper.className = "image-wrapper aspect-w-16 aspect-h-9";

      const img = document.createElement("img");
      img.src = images[photoOrder[0]].url;
      img.className = "object-cover";

      imageWrapper.appendChild(img);
      imageGrid.appendChild(imageWrapper);
    } else {
      for (let i = 0; i < numberOfPhotos; i++) {
        const imageWrapper = document.createElement("div");
        imageWrapper.className = "image-wrapper relative overflow-hidden";

        if (numberOfPhotos === 3 && i !== 0) {
          imageWrapper.style.paddingTop = "50%";
        } else if (numberOfPhotos === 4) {
          imageWrapper.style.paddingTop = "50%";
        } else {
          imageWrapper.style.paddingTop = "100%";
        }

        const img = document.createElement("img");
        img.src = images[photoOrder[i]].url;
        img.className = "absolute top-0 left-0 w-full h-full object-cover";

        imageWrapper.appendChild(img);
        imageGrid.appendChild(imageWrapper);
      }
    }

    imageGrid.className = `image-grid grid gap-1 rounded-2xl overflow-hidden w-full ${
      numberOfPhotos === 2 ? "grid-cols-2 grid-rows-1" : ""
    } ${numberOfPhotos === 3 ? "grid-cols-2 grid-rows-2" : ""} ${
      numberOfPhotos === 4 ? "grid-cols-2 grid-rows-2" : ""
    }`;

    const imageWrappers = imageGrid.getElementsByClassName("image-wrapper");
    Array.from(imageWrappers).forEach((wrapper, index) => {
      if (numberOfPhotos === 3 && index === 0) {
        wrapper.style.gridRow = "1 / span 2";
      }
    });
  }, [images, photoOrder, numberOfPhotos, containerId]);

  useEffect(() => {
    updateLayout();
  }, [updateLayout]);

  return <div id={containerId} className="image-grid"></div>;
}

export default ImageContainer;